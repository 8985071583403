<template>
  <v-main>
    <v-container>
      <v-card class="mx-auto peeps-card" max-width="600" outlined>
        <peeps-above></peeps-above>
        <v-card-text class="pa-8">
          <div class="mb-5 text-center">
            <h1>Create A Free Account</h1>
          </div>
          <signup>
            <template v-slot:button>Create A Free Account</template>
          </signup>
          <div class="text-center">
            <v-divider class="mt-3 mb-3"></v-divider>

            <p class="lead">Already have a Peeps account?</p>
            <v-btn to="/login" large color="primary" class="text-capitalize"
              >Login</v-btn
            >
          </div>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import Signup from '@/components/Signup'
import PeepsAbove from '@/components/PeepsAbove'
export default {
  metaInfo: {
    title: 'Create Account'
  },
  components: {
    Signup,
    PeepsAbove
  }
}
</script>
