<template>
  <v-form ref="form" v-model="valid" @submit.prevent="signup()">
    <v-alert v-if="error" text color="error">{{ error }}</v-alert>
    <v-alert v-if="success" text color="success">{{ success }}</v-alert>
    <!-- <user-profile-form v-model="formData"></user-profile-form> -->
    <v-row>
      <v-col>
        <v-text-field
          outlined
          :rules="rules.requiredRule"
          label="First Name"
          v-model="formData.first_name"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          outlined
          :rules="rules.requiredRule"
          label="Last Name"
          v-model="formData.last_name"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="row-mt-3" cols="8">
        <v-text-field
          :rules="rules.emailRule"
          label="Email"
          v-model="formData.email"
          outlined
        ></v-text-field>
      </v-col>
      <v-col class="row-mt-3" cols="4">
        <v-combobox
          outlined
          label="Birth Year"
          v-model="formData.birthYear"
          :items="yearList"
        ></v-combobox>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          :rules="rules.passwordRule"
          :type="showPassword ? 'text' : 'password'"
          label="Password"
          hint="Password is case sensitive"
          :append-icon="
            showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'
          "
          @click:append="togglePassword()"
          v-model="password"
          outlined
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          :rules="rules.passwordRule"
          :type="showPassword ? 'text' : 'password'"
          :append-icon="
            showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'
          "
          @click:append="togglePassword()"
          label="Confirm Password"
          v-model="confirm_password"
          outlined
        ></v-text-field>
      </v-col>
    </v-row>
    <input type="submit" hidden />
    <v-btn
      :loading="loading"
      class="text-capitalize"
      color="success"
      block
      large
      @click="signup()"
      ><slot name="button">Sign Up</slot></v-btn
    >
  </v-form>
</template>

<script>
import rules from '@/mixins/rules'
import user from '@/mixins/user'
import firebase from 'firebase/app'
import 'firebase/auth'
import feature from '@/mixins/feature'
export default {
  mixins: [rules, user, feature],
  data: () => ({
    formData: {
      email: '',
      first_name: '',
      last_name: '',
      birthYear: ''
    },
    password: '',
    confirm_password: '',
    error: false,
    success: null,
    valid: true,
    loading: false,
    showPassword: false,
    timeout: null
  }),
  methods: {
    togglePassword() {
      this.showPassword = !this.showPassword
    },
    pauseRedirect() {
      this.$store.commit('redirect', false)
      if (this.timeout) {
        clearTimeout(this.timeout)
      }
      this.timeout = setTimeout(() => {
        this.$store.commit('redirect', true)
      }, 5000)
    },
    async signup() {
      this.error = null
      this.success = null
      this.loading = true
      this.$refs.form.validate()

      this.password = this.password.trim()
      this.confirm_password = this.confirm_password.trim()
      this.formData.email = this.formData.email.trim()
      this.formData.first_name = this.formData.first_name.trim()
      this.formData.last_name = this.formData.last_name.trim()

      if (this.password != this.confirm_password) {
        this.error = 'Password and confirm password must be same'
      }
      if (this.valid && this.password == this.confirm_password) {
        this.pauseRedirect()
        await firebase
          .auth()
          .createUserWithEmailAndPassword(this.formData.email, this.password)
          .then(async res => {
            if (res) {
              let formData = {
                ...this.formData,
                last_login: new Date(),
                created_at: new Date()
              }
              try {
                this.updateUserDetail(res.user.uid, formData)
                this.initFeatures(res.user)
              } catch (err) {
                console.log(err)
              }
              this.$store.commit('auth', true)
              this.success = 'Account created, Log In to continue'
              this.$router.push('/parcel-intro')
              this.$refs.form.reset()
            }
          })
          .catch(error => {
            if (error.message) {
              this.error = error.message
            } else {
              this.error = 'Something went wrong'
            }
          })
      }
      this.loading = false
    }
  }
}
</script>

<style></style>
