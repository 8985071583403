<template>
  <v-img
    width="150"
    class="peeps-img"
    src="@/assets/img/PeepsAbove.svg"
  ></v-img>
</template>

<script>
export default {}
</script>

<style>
.peeps-img {
  margin-top: -116px;
  margin-bottom: -15px;
  margin-left: auto;
  margin-right: auto;
}
.peeps-card {
  margin-top: 110px;
}
</style>
